@import "base/helpers";

@include component(search-toggle) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;

  // Component ----------------------
  border: none;
  cursor: pointer;
  padding: 8px;
  line-height: 1;

  @include breakpoint(tablet) {
    width: 100%;
  }

  // Parts ----------------------
  @include part(toggle) {
    cursor: pointer;
    display: block;
    margin: 0; // Override legacy CSS
    position: relative;
    width: 32px; // Specify width to prevent toggle shifting
    z-index: 100;
  }

  @include part(toggle-text) {
    font-family: $font_familyHeading;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.7px;
    text-align: center;
    text-transform: uppercase;

    @include option(open) {
      display: block;

      .search-toggle__checkbox:checked ~ .search-toggle__toggle & {
        display: none;
      }
    }
  }
}
