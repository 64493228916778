@import "base/helpers";

@include component(instant-search) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $clr_textSubdued: lighten($clr_text, 30%) !default;
  $font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $timing_fast: 0.2s !default;
  $timing_slow: 0.8s !default;
  $header-height-mobile: 62px !default;
  $header-height-desktop: 68px !default;
  $inputs_borderColor: lighten($clr_textSubdued, 30%) !default;

  // Component ----------------------
  line-height: 1;

  @include breakpoint(tablet) {
    width: 100%;
  }

  // Parts ----------------------
  @include part(checkbox) {
    opacity: 0%;
    position: absolute;
    visibility: hidden;
  }

  @include part(overlay) {
    background-color: rgba(0, 0, 0, 0.52);
    bottom: -9999px;
    display: none;
    left: -999px;
    opacity: 0%;
    pointer-events: none;
    position: fixed;
    right: -999px;
    top: -999px;
    transition: opacity $timing_fast ease-in-out;

    .instant-search__checkbox:checked ~ & {
      display: block;
      opacity: 100%;
      pointer-events: auto;
      z-index: 100;
    }
  }

  @include part(overlay-toggle) {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include part(overlay-toggle-text) {
    @include visuallyHidden;
  }

  @include part(container) {
    left: 0;
    opacity: 0%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $timing_fast ease-in-out;
    visibility: hidden;
    z-index: 100;

    // When open/visible
    .instant-search__checkbox:checked ~ & {
      opacity: 100%;
      pointer-events: auto;
      visibility: visible;
    }
  }

  @include part(container-close) {
    cursor: pointer;
    font-size: 24px;
    font-weight: 400; // Override inherited legacy style

    @include breakpoint(tablet) {
      font-size: 32px;
    }
  }

  @include part(header) {
    background-color: #fff;
    border-bottom: 1px solid $inputs_borderColor;
  }

  @include part(header-inner) {
    @include container;

    align-items: center;
    display: flex;
    height: $header-height-mobile;
    justify-content: space-between;

    @include breakpoint(tablet) {
      height: $header-height-desktop;
    }
  }

  @include part(input-container) {
    margin-right: 16px;
    position: relative;
    width: 100%;
  }

  @include part(input) {
    appearance: none; // Remove shadow on iOS
    border: 1px solid $inputs_borderColor;
    border-radius: 32px;
    box-shadow: none;
    font-family: $font_familyHeading;
    font-size: 16px; // Prevent input zoom on iOS
    height: 32px;
    line-height: 1;
    outline: none;
    padding: 4px 32px 4px 16px;
    transition: border-color $timing_fast ease-in-out;
    width: 100%;

    &[type="search"] {
      box-sizing: border-box; // Increase specificity to override inherited legacy style
    }

    @include breakpoint(tablet) {
      font-size: 18px;
      height: 44px;
      padding: 8px 48px 8px 24px;
    }

    &::placeholder {
      color: $inputs_borderColor;
      font-size: 16px;
      font-weight: 300;
      opacity: 100%;
      padding-bottom: 2px;
      padding-top: 2px;

      @include breakpoint(tablet) {
        font-size: 18px;
      }
    }

    &:focus {
      border: 1px solid $clr_brandPrimary;
    }
  }

  @include part(input-spinner) {
    height: 16px;
    position: absolute;
    right: 12px;
    top: -2px;
    transform: translateY(50%);
    width: 16px;

    @include breakpoint(tablet) {
      height: 22px;
      right: 16px;
      width: 22px;
    }

    // Styles for search spinner
    > div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      margin: 2px;
      border: 2px solid $inputs_borderColor;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $inputs_borderColor transparent transparent transparent;

      @include breakpoint(tablet) {
        height: 22px;
        width: 22px;
      }

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  @include part(results) {
    background-color: #fff;
  }

  @include part(results-block) {
    $grid-gutter-width: 30px !default;
    $grid-half-gutter-width: $grid-gutter-width * 0.5 !default;

    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    padding-bottom: 24px;
    padding-left: $grid-half-gutter-width;
    padding-right: $grid-half-gutter-width;
    padding-top: 24px;
    width: 100%;

    @include breakpoint(phablet) {
      padding-bottom: 36px;
      padding-top: 36px;
    }

    @include breakpoint(tablet) {
      padding-bottom: 48px;
      padding-top: 48px;
    }

    @include breakpoint(laptop) {
      padding-bottom: 60px;
      padding-top: 60px;
    }
  }

  @include part(results-heading) {
    color: $clr_text;
    display: block;
    font-family: $font_familyHeading;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.16667;
    margin: 0;
    margin-bottom: 1em;
    padding: 0;
    text-transform: uppercase;

    @include breakpoint(tablet) {
      font-size: 14px;
    }
  }

  @include part(message) {
    color: $clr_text;
    display: block;
    font-family: $font_familyHeading;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    padding: 0;

    @include breakpoint(tablet) {
      font-size: 20px;
    }
  }

  @include part(action) {
    margin-top: 16px;

    @include breakpoint(phablet) {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }

    @include breakpoint(tablet) {
      margin-top: 32px;
    }

    @include breakpoint(laptop) {
      margin-top: 40px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  @include part(action-cta) {
    margin-left: auto;
    margin-right: auto;
    max-width: 280px;

    @include breakpoint(phablet) {
      margin-left: 0;
      margin-right: 0;
      max-width: none;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
