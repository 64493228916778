@import "../../base/helpers";

@include component(icon) {
  // Variables ----------------------

  // Component ----------------------
  display: inline-block;
  line-height: 1;
  vertical-align: top;

  // Options ----------------------
  // No options

  // Parts ----------------------
  // No parts
  @include part(redacted) {
    svg {
      fill: black;
    }
  }
}
